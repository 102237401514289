<template>
  <div>
    <navi-gation />
    <div class="box" style="padding: 5px 5px 5px 15px">
      <el-row :gutter="4" class="mt15" style="display: flex; margin-top: 6px">
        <el-col :span="3">
          <el-date-picker :clearable="false" size="small" style="width: 100%" v-model="formInline.startTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
          </el-date-picker>
        </el-col>
        <el-col :span="0.5">
   <span style="line-height: 30px; color: #dcdfe6">~</span>
</el-col>
        <el-col :span="3">
          <el-date-picker :clearable="false" size="small" style="width: 100%" v-model="formInline.endTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <el-select style="width: 100%" size="small" v-model="queryData.level" @change="logTypeChange" placeholder="请选择">
            <el-option v-for="item in level" :key="item.valuecx2"  :label="$t(`auditor.ManagementLog.LogLevel.${item.label1}`)" :value="item.valuecx2">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select style="width: 100%" size="small" v-model="queryData.module" @change="searchTypeChange" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="$t(`auditor.ManagementLog.SystemConfiguration.${item.label}`)" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary"  size="small" @click="onSubmit">{{$t('public.Inquire')}}</el-button>
        </el-col>
      </el-row>
      <div class="button" style="margin-top: 10px">
        <el-button-group>
          <el-button type="primary" @click="resetForm()" size="small" style="margin-right: 0.3px">
            {{$t('public.Derive')}}
          </el-button>
          <el-button type="primary" size="small" @click="statistical">
            {{$t('auditor.ManagementLog.ManagementLogStatistics')}}
          </el-button>
        </el-button-group>
      </div>
      <div class="content">
        <el-row style="margin-top: 10px">
          <el-table size="mini" stripe ref="multipleTable" :data="tableData" class="sort_table" tooltip-effect="dark" style="width: 100%" :header-cell-style="{
              background: '#f7f8fa',
              color: '#606266',
              padding: '0px',
            }" :default-sort="{
              prop: 'createtime',
              order: 'descending',
            }" @sort-change="sort">
            <!--  sortable="custom" -->
            <el-table-column prop="loginname" :show-overflow-tooltip="true" :label="$t('public.Username')" width="150">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.loginname }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="groupfullname" :label="$t('public.Section')" width="150">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.groupfullname }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="action" :label="$t('public.Controls')" width="150">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.action }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="actionresult" :show-overflow-tooltip="true" :label="$t('auditor.ManagementLog.ManagementLogLit.OperationResult')" width="150">
              <template v-slot="{ row }">
                <span v-if="row.actionresult == 0"> {{$t('auditor.ManagementLog.ManagementLogLit.Lose')}} </span>
                <span v-if="row.actionresult == 1"> {{$t('auditor.ManagementLog.ManagementLogLit.Successful')}} </span>
              </template>
            </el-table-column>
            <el-table-column prop="logslevel" :show-overflow-tooltip="true" :label="$t('auditor.ManagementLog.ManagementLogLit.LogLevel')" width="150">
              <template v-slot="{ row }">
                <span v-if="row.logslevel == 1"> {{$t('auditor.ManagementLog.ManagementLogLit.normal')}} </span>
                <span v-if="row.logslevel == 2"> {{$t('auditor.ManagementLog.ManagementLogLit.importance')}} </span>
                <span v-if="row.logslevel == 3"> {{$t('auditor.ManagementLog.ManagementLogLit.GiveAnAlarm')}} </span>
              </template>
            </el-table-column>
            <el-table-column prop="ipaddress" :show-overflow-tooltip="true" :label="$t('auditor.ManagementLog.ManagementLogLit.IPAddress')" width="150">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.ipaddress }}
                </span>
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="memo" :label="$t('auditor.ManagementLog.ManagementLogLit.remark')" width="150">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.memo }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="createtime" :show-overflow-tooltip="true" :label="$t('auditor.ManagementLog.ManagementLogLit.time')">
              <template slot-scope="scope">
                <span v-if="scope.row.createTime">{{ scope.row.createTime }}</span>
                <span v-if="scope.row.createtime" class="detaildesc">{{ scope.row.createtime }}</span>
              </template>
            </el-table-column>
          </el-table>
          <pagination :page="queryData.currentPage" :limit="queryData.pageSize" :total="queryData.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
        </el-row>
      </div>
    </div>
    <Eldialog :title="$t('auditor.ManagementLog.ManagementLogStatistics')" :width="'1000px'" :num="'470px'" :dialogVisible="visibaelFlag" @handleClose="handleClose">
      <div class="boxmanger" style="display:flex;justify-content;">
        <div class="manlef" style="width: 480px; height: 450px">
          <div id="main" style="width: 100%; height: 100%"></div>
        </div>
        <div class="manrig" style="width: 480px; height: 450px; margin-left: 20px">
          <div id="zhu" style="width: 100%; height: 100%"></div>
        </div>
      </div>
    </Eldialog>
    <!-- 提示消息框 -->
    <dialogInfo :dialogInfo="messageFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import dialogInfo from '@/components/promptMessage/index' //提示消息框
import naviGation from '@/components/hearder/index' //头部组件
import pagination from '@/components/pagination/page.vue' //分页
import Eldialog from '@/components/elDialog' //详情弹框

export default {
  // 组件
  components: {
    pagination, //分页
    Eldialog, //弹框
    naviGation, //头部
    dialogInfo //提示消息
  },
  data() {
    return {
      titleInfo: '', //提示titel
      messageFlag: false, //提示消息默认不显示
      currentPage: '', //导出全部页
      // 用户统计
      userData: [],
      // 管理统计
      mangerData: [],
      // 关联弹框默认不展示
      visibaelFlag: false,
      // 日历时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      value1: '',
      value2: '',
      // 下拉框
      options: [
        {
          value: '系统配置',
          label: '系统配置'
        },
        {
          value: '首页',
          label: '首页'
        },
        {
          value: '用户管理',
          label: '用户管理'
        },
        {
          value: '部门管理',
          label: '部门管理'
        },
        {
          value: '设备管理',
          label: '设备管理'
        },
        {
          value: '内容管理',
          label: '内容管理'
        },
        {
          value: '应用管理',
          label: '应用管理'
        },
        {
          value: '分配管理',
          label: '分配管理'
        },
        {
          value: '安全监测',
          label: '安全监测'
        },
        // {
        //   value: "流程管理",
        //   label: "流程管理",
        // },
        {
          value: '移动运维',
          label: '移动运维'
        },
        {
          value: '报表管理',
          label: '报表管理'
        },
        {
          value: '设备策略',
          label: '设备策略'
        },
        {
          value: '日志管理',
          label: '日志管理'
        }
      ],
      value: '',
      level: [
        {
          valuecx2: '',
          label1: '日志级别'
        },
        {
          valuecx2: '1',
          label1: '一般'
        },
        {
          valuecx2: '2',
          label1: '重要'
        },
        {
          valuecx2: '3',
          label1: '告警'
        }
      ],
      valuecx2: '',
      // 列表数据
      tableData: [],
      formInline: {
        // 选择日期
        startTime: '',
        endTime: ''
      },
      // 列表传参
      queryData: {
        //查询条件 （param）
        total: 0,
        currentPage: 1,
        pageSize: 10,
        orderColume: 'createtime',
        orderRule: 'DESC',
        input: '',
        module: '系统配置',
        level: ''
      },
      input: '' //查询该字段
    }
  },
  created() {},
  mounted() {
    this.formInline.endTime = this.$moment().format('YYYY-MM-DD')
    this.formInline.startTime = this.$moment()
      .subtract('days', 7)
      .format('YYYY-MM-DD')
    this.dataList() //列表
    // this.onSubmit()
    this.UserStatistics() //用户之日统计
    this.operationLog() //操作日志统计
  },
  methods: {
    // 下拉框选择 日志级别
    logTypeChange(item) {
      if (item == '日志级别') {
        this.queryData.level = ''
      } else {
        this.queryData.level = item
      }
    },
    // 下拉框--选择  默认勾选系统
    searchTypeChange(item) {
      this.queryData.module = item
    },
    // 查询
    onSubmit() {
      // if (this.queryData.input.length > 0) {
      //   this.queryData.currentPage = 1;
      // }

      this.dataList()
    },
    async dataList() {
      if (this.formInline.startTime == null) {
        // this.titleInfo = '请选择开始时间'
        // this.messageFlag = true
        this.$message({
          message: '请选择开始时间',
          type: 'error',
          offset: 100
        })
      } else if (this.formInline.endTime == null) {
        // this.titleInfo = '请选择结束时间'
        // this.messageFlag = true
        this.$message({
          message: '请选择结束时间',
          type: 'error',
          offset: 100
        })
      } else if (this.formInline.startTime > this.formInline.endTime) {
        // this.titleInfo = '开始时间不能大于结束时间'
        // this.messageFlag = true
        this.$message({
          message: '开始时间不能大于结束时间',
          type: 'error',
          offset: 100
        })
      } else {
        var param = {
          currentPage: this.queryData.currentPage,
          pageSize: this.queryData.pageSize,
          orderColume: this.queryData.orderColume,
          orderRule: this.queryData.orderRule,
          logLevel: this.queryData.level, //this.input
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
          deviceName: '',
          module: this.queryData.module //this.queryData.module ,先默认不传因为只有日志管理有值
        }
        const res = await this.$axios.post(
          '/httpServe/emmLogs/getManageDataInfo',
          param,
          true
        )
        this.tableData = res.data.content
        this.currentPage = res.data.pages
        this.queryData.total = res.data.total
      }
    },
    // 排序
    sort(column) {
      if (column.order === 'ascending') {
        this.queryData.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.queryData.orderRule = 'DESC'
      }
      this.queryData.orderColume = column.prop
      this.dataList() //再次调用表格
    },
    // 分页
    handleParentGetList(page, limit) {
      this.queryData.currentPage = page
      this.queryData.pageSize = limit
      // 调用列表
      this.dataList()
    },
    // 导出
    async resetForm() {
      let token2 = sessionStorage.getItem('Authorization')
        ? sessionStorage.getItem('Authorization')
        : 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA'

      let userString = decodeURIComponent(
        escape(window.atob(token2.split('.')[1]))
      )
      // clientType 1管理日志 2终端日志 3告警日志
      this.userId = JSON.parse(userString).userId
      var param = {
        currentPage: this.currentPage,
        pageSize: this.queryData.pageSize,
        orderColume: this.queryData.orderColume,
        orderRule: this.queryData.orderRule,
        logLevel: this.queryData.level, //this.input
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        deviceName: '',
        clientType: 1,
        module: this.queryData.module,
        groupId: this.id,
        userId: this.userId
      }
      const res = await this.$axios.postExport(
        '/httpExport/emmLogs/exportExcel',
        param,
        false,
        this.userId
      )
      const link = document.createElement('a')
      var fileName = 'Logs_' + Date.parse(new Date()) + '.xls'
      let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', decodeURI(fileName))
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    // 管理统计
    statistical() {
      this.useTop() //用户统计接口
      this.zhuTop() //管理操作接口
      this.visibaelFlag = true //提示消息弹框开启
      setTimeout(() => {
        this.UserStatistics()
        this.operationLog()
      }, 1000)
    },
    // 用户柱形图接口
    async useTop() {
      var param = {}
      const res = await this.$axios.post(
        '/httpServe/emmLogs/statisticLogsByUser',
        param,
        true
      )
      //  data":[{"name":"superemm","num":11}]}
      this.userData = res.data
      this.UserStatistics()
    },
    // 用户统计图
    UserStatistics() {
      var chartDom = document.getElementById('main')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        title: {
          text: this.$t('auditor.ManagementLog.POPup.UserLogStatistics'),
          left: 180,
          top: 5
        },
        tooltip: {
          trigger: 'item' //axis
          // axisPointer: {
          //   type: "shadow",
          // },
        },

        xAxis: {
          type: 'category',
          axisLabel: {
            rotate: 60
          },
          data: this.userData.name // ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.userData.num, // [120, 200, 150, 80, 70, 110, 130],
            type: 'bar',
            itemStyle: {
              normal: {
                color: '#FF8051'
              }
            }
          }
        ]
      }

      option && myChart.setOption(option)
    },
    // 用户柱形图接口
    async zhuTop() {
      var param = {}
      const res = await this.$axios.post(
        '/httpServe/emmLogs/statisticLogsByType',
        param,
        true
      )
      //  data":[{"name":"superemm","num":11}]}
      this.mangerData = res.data
      this.operationLog()
    },

    // 管理统计图
    operationLog() {
      var chartDom = document.getElementById('zhu')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        title: {
          text: this.$t('auditor.ManagementLog.POPup.OperationLogStatistics'),
          left: 180,
          top: 5
        },
        tooltip: {
          trigger: 'item' //axis
          // axisPointer: {
          //   type: "shadow",
          // },
        },

        xAxis: {
          type: 'category',
          axisLabel: {
            rotate: 60
          },
          data: this.mangerData.name //["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.mangerData.num, //[120, 200, 150, 80, 70, 110, 130],
            type: 'bar',
            itemStyle: {
              normal: {
                color: '#FF8051'
              }
            }
          }
        ]
      }

      option && myChart.setOption(option)
    },
    //确定的关闭
    determine() {
      this.messageFlag = false
    },
    // 弹框点击关闭事件
    handleClose() {
      this.visibaelFlag = false //提示弹框关闭
      this.messageFlag = false //提示消息关闭
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
